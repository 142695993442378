import jQuery from 'jquery';
import {ajaxCall, getSessionStorage, setSessionStorage, States} from './sysstat_helper';

const stateCtl = {
    backend: process.env.NEBULA_HOST.backend,
    gui_ver: process.env.DEFAULT_GUI_VER,
    maintaining: jQuery.Deferred(),
    uri: {
        maintenance: '/cc/MAINTENANCE',
        auth: '/nebula/v3/account/auth',
        orgList: '/nebula/v3/organization',
        logout: '/nebula/v3/account/logout',
        preference: '/nebula/v3/account/preferences/v2.user-interface',
        main: {
            ui: '/cc/ui/index.html',
            default: '/cc/ui/index.html',
        },
    },
    _check_maintain: function (status) {
        let resp = jQuery.Deferred();
        jQuery
            .ajax({
                url: `${location.protocol}//${location.host}${this.uri.maintenance}`,
                type: 'HEAD',
                cache: false,
            })
            .done(function () {
                console.info('[nebula_check_maintenance] - success');
                resp.resolve(States.MAINTAINING);
            })
            .fail(function () {
                console.info('[nebula_check_maintenance] - not found');
                resp.resolve(null);
            });
        return resp.promise();
    },
    _login: function (mzcUserInfo) {
        let resp = ajaxCall({
            url: `${this.backend}${this.uri.auth}`,
            type: 'POST',
            data: {user_info: mzcUserInfo},
            errmsg: '[nebula_auth_login] get nebula auth token failed.',
        });
        return resp;
    },
    _logout: function (authtoken) {
        if (!authtoken) {
            return;
        }
        let resp = ajaxCall({
            url: `${this.backend}${this.uri.logout}`,
            type: 'DELETE',
            data: {},
            headers: {
                'x-auth-token': authtoken,
            },
            errmsg: '[nebula_auth_logout] unable to logout.',
        });
        return resp;
    },
    _check_orgs: function (mzcUserInfo) {
        let _d = jQuery.Deferred();
        this._login(mzcUserInfo)
            .then((data) => {
                setSessionStorage('x-auth-token', `"${data.body.authtoken}"`);
                setSessionStorage('storage-profile', JSON.stringify({info: mzcUserInfo}));
                setSessionStorage('nebula://session/x-auth-token', `"${data.body.authtoken}"`);
                setSessionStorage('nebula://session/mzc-user-info', `"${mzcUserInfo}"`);
                return ajaxCall({
                    url: `${this.backend}${this.uri.preference}`,
                    type: 'GET',
                    headers: {'x-auth-token': data.body.authtoken},
                    errmsg: '[nebula_get_user_preference] get user preference failed',
                }).then((pref) => {
                    if (pref.body && pref.body.version && this.uri.main[pref.body.version] != null) {
                        this.gui_ver = pref.body.version;
                    }
                    return ajaxCall({
                        url: `${this.backend}${this.uri.orgList}`,
                        type: 'GET',
                        headers: {'x-auth-token': data.body.authtoken},
                        errmsg: '[nebula_get_org_list] get nebula auth token failed.',
                    });
                });
            })
            .then(function (data) {
                _d.resolve(data.body.length ? States.NORMAL_WITH_ORG : States.NORMAL_NO_ORG);
            })
            .fail(function () {
                _d.resolve(States.NORMAL);
            });
        return _d.promise();
    },
    isMaintain: function (forceStatus) {
        return jQuery
            .when(forceStatus)
            .then((status) => {
                // check maintain
                return status !== null ? jQuery.when(status) : this._check_maintain();
            })
            .then((status) => {
                this.maintaining.resolve(status);
                return status;
            });
    },
    check: function (accessToken, mzcUserInfo) {
        return this.maintaining.promise().then((status) => {
            // check orgs
            return status !== null ? jQuery.when(status) : this._check_orgs(mzcUserInfo);
        });
    },
    enter: function () {
        console.info('goto nebula');
        let entrypoint = this.uri.main[this.gui_ver];
        if (!entrypoint) {
            entrypoint = this.uri.main.default;
        }
        if (getSessionStorage('nebula://session/continuity')) {
            entrypoint = this.uri.main.ui;
        }
        location.href = `${location.protocol}//${location.host}${entrypoint}`;
    },
    logout: function (accessToken) {
        const token = (getSessionStorage('nebula://session/x-auth-token') || '').replace(/"/g, '') || accessToken;
        let _d = jQuery.Deferred();
        if (token) {
            this._logout(token).always(function () {
                _d.resolve();
            });
        } else {
            _d.resolve();
        }
        return _d.promise();
    },
};

export default stateCtl;
